class administradorService {//servicios para login
  axios
  baseUrl

  constructor(axios, baseUrl) {
    this.axios = axios;
    this.baseUrl = `${baseUrl}`;
  }

  // NEW SERVICES
  create_reservation(item) {
    return this.axios.post(`${this.baseUrl}/reservation`, item);
  }

  update_reservation_user(item) {
    return this.axios.put(`${this.baseUrl}/reservation/reservation_user`, item);
  }

  verifiedAvailability(item) {
    return this.axios.post(`${this.baseUrl}/reservation/verify_available`, item);
  }

  findEmail(item) {
    return this.axios.post(`${this.baseUrl}/personal_info/find_email`, item);
  }

  sendEmail(item) {
    return this.axios.post(`${this.baseUrl}/personal_info/send_confirmation_email`, item);
  }

  verifiedEmail(item) {
    return this.axios.put(`${this.baseUrl}/personal_info/confirm_email`, item);
  }

  get_info_reservation(id) {
    return this.axios.get(`${this.baseUrl}/reservation/find_reservation/${id}`);
  }

  validTime(id) {
    return this.axios.get(`${this.baseUrl}/reservation/timing_reservation/${id}`);
  }

  cancelReservation(body) {
    return this.axios.delete(`${this.baseUrl}/reservation/reservation_cancel`, { data: body });
  }

  cancelReservationStatus(body) {
    return this.axios.put(`${this.baseUrl}/reservation/cancel_reservation_by_token`,  body );
  }

  get_all_reservation(status_id, date) {
    return this.axios.get(`${this.baseUrl}/reservation`,
      {
        params: { status_id: status_id, date: date }
      });
  }

  updateReservationDetail(item) {
    return this.axios.put(`${this.baseUrl}/reservation/reservation_transport`, item);
  }

  finish_reservation_by_token(item){
    return this.axios.put(`${this.baseUrl}/reservation/finish_reservation_by_token`,item);
  }

  insert_personal_info(item) {
    return this.axios.post(`${this.baseUrl}/personal_info`, item);
  }



  // OLD SERVICES
  getBrandVehicle() {
    return this.axios.get(`${this.baseUrl}/brand_vehicle`);
  }

  deleteBrandVehicle(id) {
    return this.axios.delete(`${this.baseUrl}/brand_vehicle`,
      {
        params: { id: id }
      });

  }

  createBrandVehicle(item) {
    return this.axios.post(`${this.baseUrl}/brand_vehicle`, item);
  }

  updateBrandVehicle(item) {
    return this.axios.put(`${this.baseUrl}/brand_vehicle`, item);
  }

  getTypeVehicle() {
    return this.axios.get(`${this.baseUrl}/type_vehicle`);
  }

  deleteTypeVehicle(id) {
    return this.axios.delete(`${this.baseUrl}/type_vehicle`,
      {
        params: { id: id }
      }
    );
  }

  createTypeVehicle(item) {
    return this.axios.post(`${this.baseUrl}/type_vehicle`, item);
  }

  updateTypeVehicle(item) {
    return this.axios.put(`${this.baseUrl}/type_vehicle`, item);
  }

  getVehicle() {
    return this.axios.get(`${this.baseUrl}/vehicle`);
  }

  createVehicle(item) {
    return this.axios.post(`${this.baseUrl}/vehicle`, item);
  }

  updateVehicle(item) {
    return this.axios.put(`${this.baseUrl}/vehicle`, item);
  }

  getExchangeRate() {
    return this.axios.get(`${this.baseUrl}/exchange_rate`);
  }

  deleteExchangeRate(id) {
    return this.axios.delete(`${this.baseUrl}/exchange_rate`,
      {
        params: { id: id }
      }
    );
  }

  createExchangeRate(item) {
    return this.axios.post(`${this.baseUrl}/exchange_rate`, item);
  }

  updateExchangeRate(item) {
    return this.axios.put(`${this.baseUrl}/exchange_rate`, item);
  }

  getCurrencies() {
    return this.axios.get(`${this.baseUrl}/currency`);
  }

  createCurrency(item) {
    return this.axios.post(`${this.baseUrl}/currency`, item);
  }

  updateCurrency(item) {
    return this.axios.put(`${this.baseUrl}/currency`, item);
  }

  deleteCurrency(id) {
    return this.axios.delete(`${this.baseUrl}/currency`,
      {
        params: { id: id }
      }
    );
  }

  getMeasure_type() {
    return this.axios.get(`${this.baseUrl}/measure_type`);
  }

  createMeasure_type(item) {
    return this.axios.post(`${this.baseUrl}/measure_type`, item);
  }

  updateMeasure_type(item) {
    return this.axios.put(`${this.baseUrl}/measure_type`, item);
  }

  deleteMeasure_type(id) {
    return this.axios.delete(`${this.baseUrl}/measure_type`,
      {
        params: { id: id }
      }
    );
  }

  getFee() {
    return this.axios.get(`${this.baseUrl}/fee`);
  }

  createFee(item) {
    return this.axios.post(`${this.baseUrl}/fee`, item);
  }

  updateFee(item) {
    return this.axios.put(`${this.baseUrl}/fee`, item);
  }

  deleteFee(id) {
    return this.axios.delete(`${this.baseUrl}/fee`,
      {
        params: { id: id }
      }
    );
  }

  getPayment_type() {
    return this.axios.get(`${this.baseUrl}/payment_type`);
  }

  createPayment_type(item) {
    return this.axios.post(`${this.baseUrl}/payment_type`, item);
  }

  updatePayment_type(item) {
    return this.axios.put(`${this.baseUrl}/payment_type`, item);
  }

  deletePayment_type(id) {
    return this.axios.delete(`${this.baseUrl}/payment_type`,
      {
        params: { id: id }
      }
    );
  }

  getUser() {
    return this.axios.get(`${this.baseUrl}/user`);
  }

  createUser(item) {
    return this.axios.post(`${this.baseUrl}/user/signup`, item);
  }

  updateUser(item) {
    return this.axios.put(`${this.baseUrl}/user`, item);
  }

  deleteUser(id) {
    return this.axios.delete(`${this.baseUrl}/user`,
      {
        params: { id: id }
      }
    );
  }


  userRecovery(item) {
    return this.axios.post(`${this.baseUrl}/user/recovery`, item);
  }


  update_reservation(item) {
    return this.axios.put(`${this.baseUrl}/reservation`, item);
  }

  update_reservation_status(item) {
    return this.axios.put(`${this.baseUrl}/reservation`, item);
  }


  get_drivers_available(body) {
    return this.axios.post(`${this.baseUrl}/reservation/verify_driver`, body);
  }

  get_vehicle_available(body) {
    return this.axios.post(`${this.baseUrl}/reservation/verify_vehicle`, body);
  }

  get_info_reservation_driver(id) {
    return this.axios.get(`${this.baseUrl}/reservation/driver`,
      {
        params: { driver_id: id }
      }
    );
  }

  get_info_reservation_client(id, calification) {
    return this.axios.get(`${this.baseUrl}/reservation/client`,
      {
        params: { client_id: id, calification: calification }
      }
    );
  }

  create_qualification(item) {
    return this.axios.post(`${this.baseUrl}/reservation/qualification`, item);
  }

  get_claim_types() {
    return this.axios.get(`${this.baseUrl}/claim/claim_type`);
  }

  create_claim(item) {
    return this.axios.post(`${this.baseUrl}/claim`, item);
  }

  get_all_claim() {
    return this.axios.get(`${this.baseUrl}/claim`);
  }

  update_claim(item) {
    return this.axios.put(`${this.baseUrl}/claim`, item);
  }


  get_all_reservation_claim(client_id) {
    return this.axios.get(`${this.baseUrl}/reservation/client_claims`,
      {
        params: { client_id: client_id }
      });
  }

  confirm_reset_password(body) {
    return this.axios.post(`${this.baseUrl}/user/reset-password`, body);
  }

  get_all_destination() {
    return this.axios.get(`${this.baseUrl}/destination`);
  }

  get_all_origin() {
    return this.axios.get(`${this.baseUrl}/origin`);
  }

  post_origin(item) {
    return this.axios.post(`${this.baseUrl}/origin`, item);
  }

  put_origin(item) {
    return this.axios.put(`${this.baseUrl}/origin`, item);
  }

  delete_origin(id) {
    return this.axios.delete(`${this.baseUrl}/origin`,
      {
        params: { id: id }
      }
    );
  }

  post_destination(item) {
    return this.axios.post(`${this.baseUrl}/destination`, item);
  }

  put_destination(item) {
    return this.axios.put(`${this.baseUrl}/destination`, item);
  }

  delete_destination(id) {
    return this.axios.delete(`${this.baseUrl}/destination`,
      {
        params: { id: id }
      }
    );
  }

  get_all_country() {
    return this.axios.get(`${this.baseUrl}/country`);
  }

  post_country(item) {
    return this.axios.post(`${this.baseUrl}/country`, item);
  }

  put_country(item) {
    return this.axios.put(`${this.baseUrl}/country`, item);
  }

  delete_country(id) {
    return this.axios.delete(`${this.baseUrl}/country`,
      {
        params: { id: id }
      }
    );
  }

  get_all_department() {
    return this.axios.get(`${this.baseUrl}/departments`);
  }

  post_department(item) {
    return this.axios.post(`${this.baseUrl}/departments`, item);
  }

  put_department(item) {
    return this.axios.put(`${this.baseUrl}/departments`, item);
  }

  delete_department(id) {
    return this.axios.delete(`${this.baseUrl}/departments`,
      {
        params: { id: id }
      }
    );
  }

  get_all_municipality() {
    return this.axios.get(`${this.baseUrl}/municipalities`);
  }

  post_municipality(item) {
    return this.axios.post(`${this.baseUrl}/municipalities`, item);
  }

  put_municipality(item) {
    return this.axios.put(`${this.baseUrl}/municipalities`, item);
  }

  delete_municipality(id) {
    return this.axios.delete(`${this.baseUrl}/municipalities`,
      {
        params: { id: id }
      }
    );
  }
}

export default administradorService;