
import { VueTelInput } from 'vue-tel-input';


export default {
    name: 'reservaciones',

    components: {
        VueTelInput
    },
    data() {
        return {

            overlay: false,

            e1: 2,

            reserve: {
                pick_up_place: null,
                drop_off_place: null,
                pick_up_date: null,
                pick_up_time: null,
                reservation_detail:
                    [
                        { type: 1, description: 0 },
                        { type: 2, description: 0 },
                        { type: 3, description: 0 },
                        { type: 4, description: 0 },
                        { type: 5, description: 0 },
                    ],
                personal_info: {
                    phone: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                    email_exists: null
                }
            },

            passengers: {
                adults: null,
                children: null,
            },

            firstName: null,
            lastName: null,
            email: null,
            phone: null,

            bags: null,
            flightNumber: null,
            notes: null,

            origins: [
                { name: 'Cancún', code: 'CUN' },
                { name: 'Guadalajara', code: 'GDL' },
                { name: 'Ciudad de México', code: 'MEX' },
                { name: 'Monterrey', code: 'MTY' },
                { name: 'Tijuana', code: 'TIJ' },
            ],
            destinations: [
                { name: 'Cancún', code: 'CUN' },
                { name: 'Guadalajara', code: 'GDL' },
                { name: 'Ciudad de México', code: 'MEX' },
                { name: 'Monterrey', code: 'MTY' },
                { name: 'Tijuana', code: 'TIJ' },
            ],
            selectedOrigin: null,
            selectedDestination: null,
            isFormValid: true,
            selectPickUpDate: null,
            selectPickUpTime: null,
            dateFormatted: null,
            menu: false,
            date: null,
            dateFormatted: null,
            menu1: false,
            menu2: false,
            time: null,
            timeFormatted: null,
            items: [
                {
                    src: require('@/assets/fondos/pana1-1024x683.jpeg'),
                    color: 'white'
                }, {
                    src: require('@/assets/fondos/semucchampey.jpeg.webp'),
                    color: 'white'
                }
            ],
            fieldRules: [
                v => !!v || 'Field is required',
            ],
        }
    },

    methods: {

        addPassenger(type) {
            if (type === 'adults') {
                this.passengers.adults++;
            } else {
                this.passengers.children++;
            }
        },

        removePassenger(type) {
            if (type === 'adults') {
                if (this.passengers.adults > 1) {
                    this.passengers.adults--;
                }
            } else {
                if (this.passengers.children > 0) {
                    this.passengers.children--;
                }
            }
        },

        formatDate(date) {
            console.log("formatDate", date);
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        parseDate(date) {
            console.log("parseDate", date);
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month}-${day}`
        },

        async doReserve() {
            console.log(this.$refs.reservationForm, this.isFormValid);

            //si adults es null o children es null
            if (this.passengers.adults == null || this.passengers.children == null) {
                this.menu = true;
                await this.$toast.error("Please fill all the fields", { timeout: 3000, position: 'top-right' });
                return;
            }

            if (!this.$refs.reservationForm.validate()) {
                await this.$toast.error("Please fill all the fields", { timeout: 3000, position: 'top-right' });
                return;
            }
            console.log("doReserve");

            let disponibily = await this.verifiedAvailability(this.date, this.timeFormatted);
            console.log("disponibily", disponibily);
            if (!disponibily) {
                return;
            }

            this.reserve.pick_up_place = this.getOriginName(this.selectedOrigin);
            this.reserve.drop_off_place = this.getDestinationName(this.selectedDestination);
            this.reserve.pick_up_date = this.date;
            this.reserve.pick_up_time = this.timeFormatted;
            this.reserve.reservation_detail[3].description = this.passengers.adults;
            this.reserve.reservation_detail[4].description = this.passengers.children;
            console.log(this.reserve);
            this.e1 = 2;
        },

        getOriginName(code) {
            return this.origins.find(origin => origin.code === code).name
        },

        getDestinationName(code) {
            return this.destinations.find(destination => destination.code === code).name
        },

        async completeInformation() {

            if (!this.$refs.personalForm.validate()) {
                await this.$toast.error("Please fill all the fields", { timeout: 3000, position: 'top-right' });
                return;
            }

            this.reserve.personal_info.first_name = this.firstName;
            this.reserve.personal_info.last_name = this.lastName;
            this.reserve.personal_info.email = this.email;
            this.reserve.personal_info.phone = this.phone;

            const email = await this.findEmail();
            console.log("email", email);

            if (email == false) {
                await this.insertPersonalInfo();
                await this.sendEmail();
                this.reserve.personal_info.email_exists = false;
                return;
            } else if (email == true) {
                this.reserve.personal_info.email_exists = email;
            } 

            this.e1 = 3;
            console.log(this.reserve);
        },

        async reservar() {

            if (!this.$refs.optionalForm.validate()) {
                await this.$toast.error("Please fill all the fields", { timeout: 3000, position: 'top-right' });
                return;
            }

            try {
                this.overlay = true;

                this.reserve.reservation_detail[0].description = this.bags;
                this.reserve.reservation_detail[1].description = this.flightNumber;
                this.reserve.reservation_detail[2].description = this.notes;


                this.dialog_confirm = false;
                this.loading = true;
                this.reserve.email = this.email;
                let r = await this.$store.state.services.administradorService.create_reservation(this.reserve);
                console.log(r);
                if (r.status == 201) {
                    // limpiar formulario
                    this.clear();
                    this.dialog_success = true;
                    await this.$toast.info("Your reserve was sent", { timeout: 5000, position: 'top-right' });
                    this.loading = false;
                } else {
                    this.$toast.error("Error en la verificacion de correo electronico")
                    this.loading = false;
                }

                this.overlay = false;
            } catch (error) {
                this.overlay = false;
                console.log(error);
                this.$toast.error("Not is posible to do reserve", { timeout: 2000, position: 'top-right' });
                this.loading = false;
            }
        },

        clear() {
            this.reserve = {
                pick_up_place: null,
                drop_off_place: null,
                pick_up_date: null,
                pick_up_time: null,
                reservation_detail:
                    [
                        { type: 1, description: 0 },
                        { type: 2, description: 0 },
                        { type: 3, description: 0 },
                        { type: 4, description: 0 },
                        { type: 5, description: 0 },
                    ],
                personal_info: {
                    phone: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                }
            }
            this.passengers = {
                adults: null,
                children: null,
            }
            this.firstName = null;
            this.lastName = null;
            this.email = null;
            this.phone = null;
            this.bags = null;
            this.flightNumber = null;
            this.notes = null;
            this.selectedOrigin = null;
            this.selectedDestination = null;
            this.selectPickUpDate = null;
            this.selectPickUpTime = null;
            this.menu = false;
            this.date = null;
            this.menu1 = false;
            this.menu2 = false;


            this.e1 = 1;

            // ñimpiar formulario
            this.$refs.reservationForm.reset();
            this.$refs.personalForm.reset();
            this.$refs.optionalForm.reset();
        },

        async verifiedAvailability(date, time) {
            try {
                this.overlay = true;
                let r = await this.$store.state.services.administradorService
                    .verifiedAvailability(
                        {
                            date: date,
                            time: time
                        }
                    )
                console.log("respuesta", r);
                this.overlay = false;
                if (r.status == 201) {
                    // informa al cliente que hay disponibilidad
                    this.$toast.success("Great, we have avaialability", { timeout: 2000, position: 'top-right' });
                    return true;
                } else {
                    this.$toast.warning("Sorry, there is no availability for the selected date and time", { timeout: 2000, position: 'top-right' });
                    return false;
                }
            } catch (error) {
                this.overlay = false;
                console.log(error);
                this.$toast.error("Error in the server", { timeout: 2000, position: 'top-right' });
                return false;
            }
        },

        async findEmail() {
            try {
                this.overlay = true;
                let r = await this.$store.state.services.administradorService
                    .findEmail(
                        {
                            email: this.email
                        }
                    )
                console.log("respuesta", r);
                this.overlay = false;
                return r.data.message;
            } catch (error) {
                this.overlay = false;
                console.log(error);
                this.$toast.error("Error in the server", { timeout: 2000, position: 'top-right' });
                return null;
            }
        },

        async sendEmail() {
            try {
                this.overlay = true;
                let r = await this.$store.state.services.administradorService
                    .sendEmail(
                        {
                            email: this.email
                        }
                    )
                await this.$toast.info("An email has been sent to your inbox for confirmation.", { timeout: 5000, position: 'top-right' });
                console.log("respuesta", r);
                this.overlay = false;
                return r.data.message;
            } catch (error) {
                this.overlay = false;
                console.log(error);
                this.$toast.error("Error in the server", { timeout: 2000, position: 'top-right' });
                return null;
            }
        },

        async insertPersonalInfo() {
            try {
                this.overlay = true;
                let r = await this.$store.state.services.administradorService
                    .insert_personal_info(
                        this.reserve
                    )
                console.log("respuesta", r);
                this.overlay = false;
            } catch (error) {
                this.overlay = false;
                console.log(error);
                this.$toast.error("Error in the server", { timeout: 2000, position: 'top-right' });
            }
        },
    },

    mounted() {
        console.log("Vamos a realizar una reservacion");
    },

    watch: {

        date(val) {
            console.log("date", val);
            this.dateFormatted = this.formatDate(val)
        },
        time(val) {
            console.log("time", val);
            this.timeFormatted = val
        }
    },

    computed: {

        totalPassengers() {
            return +(this.passengers.adults || 0) + +(this.passengers.children || 0);
        },

        mainStyle() {
            return this.$vuetify.breakpoint.smAndDown ? { marginTop: '120px', zIndex: '1' } : { zIndex: '1' };
        },
    }
}
