
import logo_quetzal from '../../../assets/Q_Logo_BordeBlanco.png'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {

	components: {
		VueTimepicker,
	},

	data() {
		return {
			
			logo_quetzal,
			id: -1,
			e6: 0,
			loading: false,
			menu2: false,
			hasScroll: false,
			menu1: false,
			date: null,
			isDeleted: false,
			dateFormatted: null,
			timeFormatted: null,
			fieldRules: [
				v => !!v || 'Field is required',
			],
			time: null,
			item_aux:
			{
				reservation: {
					pick_up_date: "",
					pick_up_time: "",
					pick_up_place: "",
					drop_off_place: "",
					token: "",
					PersonalInfo: {
						first_name: "",
						last_name: "",
						phone: "",
						email: "",
					},
					OptionsReservations: [
						{
							description: "0",
							OptionsReservationType: {
								type: "MALETAS"
							}
						},
						{
							description: "",
							OptionsReservationType: {
								type: "NUMERO VUELO"
							}
						},
						{
							description: "",
							OptionsReservationType: {
								type: "NOTA"
							}
						},
						{
							description: "",
							OptionsReservationType: {
								type: "ADULTOS"
							}
						},
						{
							description: "",
							OptionsReservationType: {
								type: "NINOS"
							}
						}
					],
					Vehicle: null,
					Driver: null
				}
			},

			modal: false,
			modalDelete:false
		};
	},

	async created() {
		this.loading = true;

		// obten el parametro token
		let { token } = this.$route.params;
		console.log("el token", token);
		this.id = token;
		await this.get_info_reservation();
	},

	methods: {

		async updateReservationConfirm() {

			try {

				let disponibily = await this.verifiedAvailability();

				if (!disponibily) {
					return;
				}


				this.item_aux.reservation.pick_up_time = this.time;
				this.item_aux.reservation.pick_up_date = this.date;
				this.loading = true;
				let r = await this.$store.state.services.administradorService.update_reservation_user(this.item_aux.reservation);
				console.log("la respuesta", r);
				if (r.status == 200) {
					this.$toast.success("Your reservation has been updated",
					{
						timeout: 6000,
						position: 'bottom-right'
					});
					this.modal = false;
					this.get_info_reservation();

				}
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;
				this.$toast.error("Your reservation could not be updated");
			}
		},

		async verifiedAvailability() {
            try {
                this.loading = true;
                let r = await this.$store.state.services.administradorService
                    .verifiedAvailability(
                        {
                            date: this.date,
                            time: this.time
                        }
                )
                console.log("respuesta",r);
                this.loading = false;
                if (r.status == 201) {
                    // informa al cliente que hay disponibilidad
                    return true;
                } else {
                    this.$toast.warning("Sorry, there is no availability for the selected date and time", { timeout: 6000, position: 'top-right' });
                    return false;
                }
            } catch (error) {
                this.loading = false;
                console.log(error);
                this.$toast.error("Error in the server", { timeout: 2000, position: 'top-right' });
                return false;
            }
        },

		hasVerticalScroll(e) {
			// console.log("e data",e);
			if(this.item_aux?.reservation?.booking_changes == 0){
				this.hasScroll = e.target.defaultView.pageYOffset > 0 && e.target.defaultView.pageYOffset < 50;
			}else{
				this.hasScroll = false;
			}
		},

		updateReservation() {
			this.modal = true;
		},

		formatDate(date) {
			console.log("formatDate", date);
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},

		parseDate(date) {
			console.log("parseDate", date);
			if (!date) return null

			const [month, day, year] = date.split('/')
			return `${year}-${month}-${day}`
		},

		async get_info_reservation() {
			try {
				let r = await this.$store.state.services.administradorService.get_info_reservation(this.id);
				console.log("la respuesta", r);
				if (r.status == 200) {
					console.log(r.data);

					this.e6 = 1;
					console.log(r.data.data.status_id);
					this.item_aux.reservation = r.data.data;
					this.time = r.data.data.pick_up_time;
					this.date = r.data.data.pick_up_date;

					console.log("item_aux", this.item_aux);
					this.loading = false;

					if (r.data.data.status_id == 4) {
						this.e6 = 1;
					} else {
						this.e6 = parseInt(r.data.data.status_id);
					}
					console.log("e6", this.e6);
					this.validTime();

				} else {
					this.$toast.info("Your booking information could not be obtained");
					this.loading = false;
				}

			} catch (error) {

				this.$toast.error("Your booking information could not be obtained")
				this.loading = false;
				console.log(error);


			}

		},

		cancelReservation(){
			console.log("cancel reservation");
			this.modalDelete = true;
		},

		async validTime() {
			try {
				let r = await this.$store.state.services.administradorService.validTime(this.id);
				console.log("la respuesta", r);
				if (r.status == 200) {
					this.isDeleted = true;
				}
			} catch (error) {
				console.log(error);
				this.isDeleted = false;
			}
		},

		async confirmCancelReservation(){
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.cancelReservation({ token: this.id });
				console.log("la respuesta", r);
				if (r.status == 200) {
					await this.$toast.success("Your reservation has been canceled",
					{
						timeout: 6000,
						position: 'bottom-right'
					});
					this.modalDelete = false;
					// recarga la pantalla sin realizar ningun push
					this.$router.go();

				}
				this.loading = false;
				
			} catch (error) {
				this.loading = false;
				console.log(error);
				await this.$toast.error("Your reservation could not be canceled");
			}
		}
	},

	watch: {

		date(val) {
			console.log("date", val);
			this.dateFormatted = this.formatDate(val)
		},
		time(val) {
			console.log("time", val);
			this.timeFormatted = val
		}
	},

}

